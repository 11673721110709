.usage-container {
  margin: 10px 25px;
}
.usage-content {
  margin: 0 8%;
  border: solid thin var(--brand-color-1);
  border-radius: 20px;
  padding: 15px;
  text-align: center;
}

.module-select {
  margin: 0 10%;
  width: 50%;
  display: inline-block;
}

.action-select {
  margin: 0 10%;
  width: 50%;
  display: inline-block;
}

.charts-container {
  text-align: center;
  margin-top: 30px;
}

.charts-container h6 {
  text-decoration: underline;
}

.recharts-legend-item { font-size: 0.75rem; }