/*--Educational Library --*/

#education-field {
	margin: 10px 20px 0 0;
}

.education-column-card {
	padding: 0
}

#educational_resources .groups {
	text-align: center;
	margin: 20px;
}

#educational_resources .ant-select-selection {
  border-radius: 4px;
}

#educational_resources .tab {
	padding: 1.5vh 1vw;
  margin-bottom: 1vh;
	width: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
	/* letter-spacing: 2px; */
  background-color: white;
	color: rgba(0,0,0,1);
  display: inline-block;
  text-align: center;
	cursor: pointer;
	max-height: 60px;
	min-height: 60px;
}

#educational_resources .resource_table {
	/*margin-top: 10px;*/
	max-height: 68vh;
	min-height: 68vh;
	overflow-y: auto;
	/* border-radius: 5px; */
	/* padding: 1vh 1vw 1vh 3vw; */
	margin-bottom: 25px;
}

#educational_resources .background {
	background-color: #ebf8fd;
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
	margin-top: 20px;
	/* background-image: url('../../static/images/1382_1920.jpg'); */
  /* height: 100vh; */
	/* width: 100%; */
	/* margin-right: 25px; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}


#all-tab .tab {
	min-height: 92% !important;
	display: block;
	line-height: 45px;
	font-size: 1.5vw;
	/* letter-spacing: 2px; */
}

@media (max-width: 767px) {
	#all-tab .tab {
		line-height: 30px;
		font-size: 150%;
	}
}

@media (max-width: 1186px) {
	#educational_resources .tab.long {
		padding: 1vh 1vw;
	}
}

@media (max-width: 648px) {
	#educational_resources .tab.medium {
		padding: 1vh 1vw;
	}
}

@media (max-width: 575px) {
	#educational_resources .tab.medium, #educational_resources .tab.long {
		padding: 2vh 1vw;
	}
}

#educational_resources .tab:hover {
	box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
	background-color: var(--brand-color-2);
	color: white;
}

#educational_resources .tab.active {
	color: white;
	background-color: var(--brand-color-2);
}

#educational_resources {
	/*font-family:  "Open Sans", "Helvetica", sans-serif;*/
}


/*#educational_resources .card {*/
/*	!*font-family:  "Open Sans", "Helvetica", sans-serif;*!*/
/*	!*box-shadow: 3px 2px 6px rgba(0, 0, 0, 0.5);*!*/
/*	margin: 2vh 1vw;*/
/*	border-radius: 10px;*/
/*	padding: 1vh 0vw 0vh 0vw;*/
/*	color: white;*/
/*}*/

/*#educational_resources .ant-card {*/
/*	!*box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5) !important;*!*/
/*	border-color: var(--brand-color-2);*/
/*	border-radius: 10px;*/
/*	color: black;*/
/*	font-weight: 600;*/
/*	background-color: rgba(255, 255, 255, 0.75);*/
/*	backdrop-filter: blur(6px);*/
/*}*/

/*#educational_resources .ant-card-body {*/
/*	!*font-family:  "Open Sans", "Helvetica", sans-serif;*!*/
/*	font-size: 10pt;*/
/*	padding: 0px;*/
/*	padding-top: 12px;*/
/*	background-color: rgba(225, 225, 225, 0);*/
/*	border-top: 1px solid rgba(225, 225, 225, 1);*/
/*	border-bottom: 1px solid rgba(225, 225, 225, 1);*/
/*}*/

/*#educational_resources .ant-card-head {*/
/*	background-color: var(--brand-color-2);*/
/*}*/

/*#educational_resources .ant-card-head-title {*/
/*	color: white;*/
/*}*/

/*#educational_resources .ant-card-actions {*/
/*	background-color: var(--brand-color-2);*/
/*}*/

/*#educational_resources .ant-card-actions > li {*/
/*	padding-top: 3.5px;*/
/*	height: 30px;*/
/*	margin: 0;*/
/*}*/

/*#educational_resources .ant-card-actions > li:hover {*/
/*	background-color: white;*/
/*	color: var(--brand-color-2);*/
/*	border: 2px solid var(--brand-color-2);*/
/*}*/

/*#educational_resources .ant-card-actions > li:hover a {*/
/*	color: var(--brand-color-2);*/
/*}*/

/*#educational_resources .ant-card-actions a {*/
/*	color: white;*/
/*	font-size: 0.85em;*/
/*}*/


/*#educational_resources .card-title {*/
/*	font-weight: bolder;*/
/*	text-align: center;*/
/*	margin-left: 1vw;*/
/*	margin-right: 1vw;*/
/*}*/

/*#educational_resources .card-description {*/
/*	text-align: left;*/
/*	overflow: auto;*/
/*	height: 25vh;*/
/*	margin-left: 1vw;*/
/*	margin-right: 1vw;*/
/*}*/

#educational_resources .education-btn {
	margin-top: 1vh;
	background-color: rgba(178, 126, 230, 0.4);
	color: white;
	width: 100%;
	border: none;
	font-size: .91vw;
	border-radius: 0;
	transition: .1s;
}

#educational_resources .education-btn.edit-btn {
	border-right: solid thin white;
}

#educational_resources .education-btn.download-btn {
	border-left: solid thin white;
	border-right: solid thin white;
}

#educational_resources .education-btn:hover {
	background-color: rgba(178, 126, 230, 0.8);
}

.new_education_button {
	/* width: 100%; */
	border-radius: 20px;
	background-color: white !important;
	border-color: var(--brand-color-1) !important;
	color: var(--brand-color-1) !important;
}

.new_education_button:hover {
	background-color: var(--brand-color-2) !important;
	color: white !important;
	border-color: var(--brand-color-2) !important;
}

.new_education_group_button {
	width: 100%;
	border-radius: 20px;
	background-color: white !important;
	border-color: var(--brand-color-2) !important;
	color: var(--brand-color-2) !important;
}

.new_education_group_button:hover {
	background-color: var(--brand-color-1) !important;
	color: white !important;
	border-color: var(--brand-color-1) !important;
}

/*.education-card-filetype {*/
/*	position: absolute;*/
/*	bottom: 31px;*/
/*	width: 100%;*/
/*	text-align: center;*/
/*	background-color: #F5F6FF;*/
/*	!* border-top: solid thin var(--brand-color-2); *!*/
/*	color: var(--brand-color-2)*/
/*	!* border-radius: 15px; *!*/
/*}*/

.ant-card-head-title {
	font-size: 10pt;
	white-space: nowrap;
}

/*-- New Resource Form --*/
#neweducation .title {
	font-size: 2vmax;
}

#neweducation .field {
	color: var(--brand-color-1);
	margin-bottom: 1vh;
	font-weight: bolder;
	/*padding: 2vh, 1vw 1vh 1vw;*/
}

#neweducation .check {
	font-weight: normal;
}


#neweducation .shortInput {
	padding: .7vh .7vw;
	/* width: 95%; */
}

#neweducation .longInput {
	padding: .7vh .7vw;
	/* width: 90%; */
}

#neweducation .table {
	overflow: auto;
	max-height: 70vh;
	display: inline-block;
}

#neweducation .evidencetable {
	overflow: auto;
	max-height: 50vh;
	display: inline-block;
}

#neweducation .kvm-message {
	padding: 0vh 2vw;
}

#neweducation .kvm-label:nth-child(even) {
	background-color: #CCCBE1;
	height: 7vh;
	margin: 0;
	border: thin white solid;
}

#neweducation .kvm-label:nth-child(odd) {
	background-color: #E7E7F0;
	height: 7vh;
	margin: 0;
	border: thin white solid;
}

#neweducation #file-upload {
	font-family: sans-serif;
}

#neweducation .ant-select-selection__placeholder {
	font-weight: normal;
}


.education-card {
	border-color: var(--brand-color-2);
	margin: 10px;
	height: 35vh;
}

.education-card-header {
	background-color: var(--brand-color-2);
	color: white;
	/*font-weight: bold;*/
	text-align: center;
	font-size: 10pt;
	/*height: 40%;*/
}

.education-card-body {
	text-align: left;
	/*height: 20vh;*/
	font-size: 10pt;
	overflow: auto;
	padding: 0.50rem 1.25rem;
}

.education-card-filetype {
	padding: 5px;
	background-color: #F5F6FF;
}

.education-card-action-buttons {
	padding: 0 15px;
	vertical-align: center;
	text-align: left;
}
.education-card-action-button {
	font-size: 9pt;
	text-align: center;
	padding: 5px 0;
}
.education-card-action-button:hover {
	color: white;
	background-color: var(--brand-color-2);
	cursor: pointer;
	/*font-weight: bold;*/
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate{
  /*text-overflow:ellipsis;*/
  /*overflow:hidden;*/
  /*white-space:nowrap;*/
}

.folder {
	cursor: pointer;
	padding: 5px 5px;
}
.folder:hover {
	color: var(--brand-color-1);
	transition: .25s;
}
.folder.active {
  background-color: var(--brand-color-2);
	border-radius: 5px;
  color: white;
}


.folder-filter {
	cursor: pointer;
  margin: 5px 0px 5px 20px;
	font-size: 10pt;
}

.folder-filter:hover {
	color: var(--brand-color-1)
}

.folder-filter.active {
	/* border-bottom: solid 2px var(--brand-color-2); */
  /* background-color: var(--brand-color-2); */
  color: var(--brand-color-1);
	font-weight: bold;
}

/*-- New Evidence Form --*/
#newfolder .field {
	color: var(--brand-color-1);
	margin-bottom: 1vh;
	font-weight: bolder;
	/*padding: 2vh, 1vw 1vh 1vw;*/
}

#newfolder input {
	padding: 3px 5px 5px;
	height: 35px;
	font-size: 14px;
}
#newfolder input::placeholder {
	color: gray;
}

#FilterSideBar.transparent {
	opacity: .5;
}

#key-resource-breadcrumbs .breadcrumb {
	/*margin-top: 1rem;*/
	margin-top: 0;
	margin-bottom: 0;
	/*background-color: #ebf8fd;*/
	background-color: white;
	/*border: solid thin var(--brand-color-2);*/
}

#key-resource-breadcrumbs .breadcrumb a {
	color: var(--brand-color-1);
}

.folder.back-button {
	margin: 10px 0;
	border-bottom: solid thin var(--brand-color-2);
}

.folder-hr {
	margin: 5px 0 2.5px;
	border-top: solid thin var(--brand-color-2);
}

.folder-warning {
	padding: 5px;
	font-size: 11pt;
}