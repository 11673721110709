/*-- Factsheet --*/
.fact-loader {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 15vh 25vw 0 25vw;
	width: 50vw;
	font-size: 18px;
	/* letter-spacing: 1px; */
	position: relative;
}

.fact-loader-text {
	margin-top: 10px;
}

@media (max-width: 650px) {
	.fact-loader{
		width: 60vw;
		margin-left: 20vw;
	}
}
#factsheet {
	font-family:  "Open Sans", "Helvetica", sans-serif;
	color: #53646E;
	margin-right: 25px;
}

#factsheet th, td {
	width: 150px;
	font-size: 10pt;
}


#factsheet .factsheet-header {
  padding: 4vh 0vw 0vh .5vw;
}

#factsheet .pagination-buttons {
	text-align: center;
	margin-bottom: 2vh;
}

#factsheet .location-select {
	font-size: 1vw;
	font-size: 1vmax;
}

#factsheet .location {
	color: black;
	width: 25vw;
}

#factsheet .Select-menu-outer {
	max-height: 1000px;
}

#factsheet .Select-menu {
	max-height: 1000px;
}

#factsheet .tab {
	padding: 1vw 1vw 1vw 1vw;
  margin-bottom: 1vh;
	margin-right: 1vw;
	box-shadow: 1px 1px 5px rgba(0, 0 ,0, 0.5);
	font-size: 1vmax;
  background-color: white;
	color: rgba(0,0,0,1);
	/* letter-spacing: 2px; */
  display: inline-block;
  width: 90%;
  text-align: center;
	cursor: pointer;
}

#factsheet .tab:hover {
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
	background-color: var(--brand-color-2);
	color: white;
}

#factsheet .tab.active {
	background-color: var(--brand-color-2);
	color: white;
}

#factsheet .factsheet-table {
	width: 100%;
}

.fact-table-container {
	overflow-x: auto;
	overflow-y: auto;
	height: 70vh;
  margin: 20px 0;
}

main {
	margin-bottom: 25px;
}

#factsheet .factsheet-table th {
  margin: 0vh .5vw;
	max-height: 60vh;
	color: white;
	background-color: var(--brand-color-2);
	border-bottom: solid medium var(--brand-color-2);
  position: sticky;
  top: 0;
  z-index: 1;
	padding: 1.5vh 1vw 0.75vh 1vw;
}



#factsheet .table-header {
	font-size: 12px;
	font-weight: bolder;
	border-bottom: solid white;
}

#factsheet .table-header.description{
	width: 30vw;
	font-size: 10pt;
}

#factsheet .table-header.outcome{
	width: 15vw;
	font-size: 10pt;
}


#factsheet td .ant-btn {
	background-color: white !important;
	border-color: var(--brand-color-1) !important;
	color: var(--brand-color-1) !important;
	border-radius: 20px;
	width: 5vw;
	width: 100px;
	margin: 0 auto;
	font-size: 1rem !important;
	margin: 0 0 10px 0;
}

#factsheet td .ant-btn:hover {
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
	color: white !important
}

#factsheet .fact-row {
	border-bottom: solid medium #EEEDED;
	font-size: 12px;
	overflow: auto;
  color: black;
}

#factsheet .fact-row td {
	padding: 0.5vh;
}

#factsheet .fact-description-td {
	text-align: left;
}

#factsheet .fact-action-td {
	width: 25%;
	text-align: center;
}
#factsheet .fact-action-td .action_btn {
	margin-left: 5px;
}

#factsheet .edit_fact {
	display: inline-block;
	background-color: white !important;
	border-color: var(--brand-color-1) !important;
	color: var(--brand-color-1) !important;
	text-align: center;
	border-radius: 20px;
	width: 10vw;
	font-size: 14px;
}

#factsheet .edit_fact:hover {
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
	color: var(--brand-color-1) !important;
	color: white !important;
}

#factsheet .edit_fact_btn {
	display: inline-block;
}

#factsheet .fact-row button {
	margin-top: 1vh;
}

#factsheet .fact-row:hover {
	transition: .5s;
	background-color: rgba(0,0,0,1);
	background-color: var(--brand-color-1);
	color: white;
}

#factsheet .fact-outcome-td {
  background-color: var(--brand-color-1);
}

#factsheet .fact-row:hover td:nth-of-type(1) {
	transition: .5s;
	color: white;
	background-color: var(--brand-color-1);
}

#factsheet .fact-row td:nth-of-type(1) {
	color: white;
}

#factsheet .fact {
	background-color: white;
}

#factsheet .fact-outcome {
	transition: .25s;
	background-color: var(--brand-color-1);
	color: white;
	width: 25vw;
}

#factsheet .saved {
  background-color: transparent;
  border: none;
}

/* #factsheet .fact-button {
	font-size: 1.25vw;
	font-size: 1.25vmax;
} */

#factsheet .fact-kvm-link {
	text-align: center;
}

/*-- New DataEntry Form --*/
#newfact {
	height: 100%;
	/* width: 80%; */
	/* margin-left: 10%; */
}

#newfact .title {
	color: var(--brand-color-1);
	margin-bottom: 5px;
}

#newfact .new_evidence {
	margin-bottom: 10px;
}

#newfact .edit-fact-back-btns {
	float:right;
	left: 10px !important;
}

#newfact .next-btn {
	float: right;
	margin-left: 4px;
}

#newfact .back-btn {
	float: right;
}

#newfact .field {
	color: var(--brand-color-1);
	margin-bottom: 1vh;
	font-weight: bolder;
}

#newfact .shortInput {
	padding: .7vh .7vw;
	/* width: 90%;
	margin: 0 5px; */
}

#newfact .longInput {
	padding: .7vh .7vw;
	/* width: 90%; */
}

#newfact .gvd_location {
	width: 100%;
}

#newfact .table-header {
	color: #53646E;
	/* font-size: 1vw; */
	font-weight: bolder;
	border-bottom: solid 3px var(--brand-color-2);
}

#newfact .table {
	width: 100%;
	overflow: auto;
	max-height: 60vh;
	display: inline-block;
}

#newfact .table td:first-child, #newfact .table th:first-child {
    width: 0;
    padding: 10px;
    padding: 0;
  }

#newfact .table th {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	padding: 2vh 1vw 1vh 1vw;
	color: white;
}

#newfact .kvm-column {
	background-color: #E2E7E9;
	color: #53646E;
}

#newfact .kvm-id {
	transition: .25s;
	width: 5vw;
}

#newfact .kvm-message {
	padding: 0vh 2vw;
	/* font-size: 11pt; */
}

#newfact .kvm-label {
	height: 7vh;
	margin: 0;
	border-bottom: solid medium #EEEDED;
	transition: .25s;
	font-size: 9pt;
	color: var(--brand-color-1);
}

#newfact .kvm-label:hover {
	background-color: #b4bfc5;
	color: white;
}


#newfact .kvm-checkbox {
	transition: .25s;
	padding: 0;
	margin: 0;
	width: 10px;
}



#newfact .kvm-checkbox-header {
	width: 10% !important;
}

#newfact .kvm-label td {
	padding: 1vh 1vw 1vh 1vw;
}

#newfact .evidencetable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	display: inline-block;
	width:100%
}

#newfact .evidencetable .label {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	padding: 2vh 1vw 1vh 1vw;
}

#newfact .ant-select-selection__placeholder {
	font-weight: normal;
}

@media (max-width: 991px) {
	.large-left {
		margin-top: 15px;
	}
}
