#NavBar, .dropdown-menu, .navbar-input, .navbar-btn {
  font-size: 10pt;
}

#NavBar {
  border-bottom: solid var(--brand-color-3)
}

.topnav-drug-logo {
  width: 125px;
}

#NavBar a, #NavBar a:visited {
  color: var(--brand-color-1);
  /* font-size: 1vmax; */
}

#NavBar a:hover {
  color: var(--brand-color-2);
  text-decoration: none;
}

#NavBar a.active {
  color: var(--brand-color-2);
  font-weight: bold;
  /* border-bottom: solid; */
}

#NavBar .dropdown-item:active, #NavBar .dropdown-item.active {
  background-color: white;
  color: var(--brand-color-2);
  transition: 0s;
}
#NavBar .dropdown-item:active a {
  color: white;
  transition: 0s;
}

#NavBar .navbar-brand {
  padding-top: 0px;
}

#site-wide-filters-btn {
  background-color: white;
	border-color: var(--brand-color-2);
	border-radius: 20px;
	color: var(--brand-color-2);
}
#site-wide-filters-btn:hover {
  background-color: var(--brand-color-1);
	border-color: var(--brand-color-1);
	color: white;
}

.reactive-search-input {
  border-radius: 5px;
  font-size: 10pt !important;
  min-height: 0px !important;
  height: 32px !important;
  width: 20vw !important;
  padding: 0px;
  border-color: var(--brand-color-1) !important;
  color: var(--brand-color-1);
}

.reactive-search-list {
  width: 40vw !important;
  right: 0;

}

.cancel-icon {
  margin-bottom: 20px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: var(--brand-color-1);
}


@media (max-width: 1410px) {
  .reactive-search-input {
    width: 100%;
  }

  .topnav-drug-logo {
    width: 100px;
  }
  #NavBar, .dropdown-menu, .navbar-input, .navbar-btn {
    font-size: 9pt;
  }
}

@media (max-width: 1150px) {
  .topnav-drug-logo {
    width: 75px;
  }
  #NavBar, .dropdown-menu, .navbar-input, .navbar-btn {
    font-size: 7pt;
  }
  .reactive-search-input {
    border-radius: 5px;
    font-size: 7pt !important;
    min-height: 0px !important;
    height: 32px !important;
    width: 15vw !important;
    padding: 0px;
    border-color: var(--brand-color-1) !important;
    color: var(--brand-color-1);
  }
}

@media (max-width: 991px) {
  .topnav-drug-logo {
    width: 100px;
  }
  #NavBar, .dropdown-menu, .navbar-input, .navbar-btn {
    font-size: 9pt;
  }

}