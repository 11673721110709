/*--EGVD landing--*/
#LandingPage {
	margin-top: 10px;
}
#LandingPage .icon-circle {
	text-align: center;
	border-radius: 50%;
	padding: 5px;
	background-color: var(--brand-color-3);
}

#LandingPage .dashIcon {
	width: auto;
	height: 30px;
	padding: 5px;
	margin-bottom: 7px;
}

#LandingPage .icon-circle-top-center {
	width: auto;
	height: 30px;
	border-radius: 50%;
	/*transform: translateY(-25px);*/
}

.top-row-icon {
	transform: translateY(-68px) !important;
}


.kvm-icon {
	background-color: var(--brand-color-2);
}

.standard-icon {
	background-color: var(--brand-color-3);
}

.whats-new-icon {
	background-color: var(--brand-color-2) !important;
}

.ant-switch-checked {
	background-color: var(--brand-color-2);
}

#LandingPage .home-nav {
	 /*font-size: calc(12px + .6vw);*/
	font-size: 15pt;
	background-color: white;
	color: black;
	/* padding: 40px 20px; */
	-webkit-transition: box-shadow .1s;
	transition: .25s;
	cursor: pointer;
	border: 2px solid var(--brand-color-3);
	border-radius: 10px;
	position: relative;
	/* width: 92%; */
	min-height: 300px;
	height: 30vh;
	/*margin: 15px;*/
	text-align: left;
	overflow: auto;
}

#LandingPage .home-nav-card-row {
	margin: 0;
}

/* width */
#WhatsNew ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#WhatsNew ::-webkit-scrollbar-track {
  background: rgb(173, 183, 190);
}

/* Handle */
#WhatsNew ::-webkit-scrollbar-thumb {
  /* background: rgb(228, 95, 58); */
  background: rgba(0,0,0,1);
}

/* Handle on hover */
#WhatsNew ::-webkit-scrollbar-thumb:hover {
  background: #777;
}

#WhatsNew .whats-new-link {
	color: var(--brand-color-1);
}

#LandingPage .home-nav.home-nav-kvms {
	border: 2px solid var(--brand-color-3);
	border-image-slice: 1;
}

#LandingPage .home-nav.facts {
	padding: 0vh 1vw;
	background-color: white;
	color: black;
	transition: .2s;
}

#LandingPage .home-nav.facts:hover {
	box-shadow: 0px 0px 0px rgba(0, 0 ,0, 0);
}

#LandingPage #WhatsNew {
  margin: 25px 15px;
  min-height: 390px !important;
  cursor:auto;
	overflow: auto;
  border: 2px solid var(--brand-color-2);
  transition: .2s;
  box-shadow: none;
}

.whats-new-heading {
  color: black;
  /* border-bottom: 2px solid rgba(102, 113, 122, 1); */
  border-bottom: 1px solid var(--brand-color-2);
	font-size: 13pt;
	/* letter-spacing: 2.5px; */
}

.home-nav-headline {
	text-align: center;
	/* letter-spacing: 5px; */
}

#LandingPage #affiliate-dashboard {
	min-height: 50vh !important;
	max-height: 60vh !important;
	margin: 0 auto;
}

#LandingPage .home-nav:hover {
	box-shadow: 13px 10px 15px rgba(0, 0, 0, 0.5);
}

#LandingPage .home-nav:hover .icon-circle-bottom-left {
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

#LandingPage .go-to-fact-sheet {
	margin-left: 10px;
}

#LandingPage .circle {
	width: 7vw;
	height: 7vw;
	min-height: 85px;
	min-width: 85px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
	cursor: pointer;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
	display: table-cell;
	padding: 30px 0;
	text-align: center;
	vertical-align: middle;
}


#LandingPage .circle.global {
	background-color: white;
	transition: 0.5s;
}

#LandingPage .circle.global:hover {
	color: var(--brand-color-2);
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
	font-weight: bold;
}

#LandingPage .circle.local {
	color: white;
	background-color: rgba(255, 209, 0, 1);
	transition: 0.5s;
}

#LandingPage .circle.local:hover {
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
	color: var(--brand-color-2);
	font-weight: bold;
}

#LandingPage .circle img {
	height: 1.5vw;
	width: auto;
	margin-top: 2vh;
}


#LandingPage .list {
	/* font-size: calc(11px + .25vw); */
	font-size: 11pt;
	margin-top: 2vh;
	padding-left: 20px;
	padding-right: 20px;
}

#LandingPage .list.center {
	text-align: center;
}

#LandingPage .list .highlight {
	margin-bottom: 1vh;
}

#LandingPage .bottomNav {
	/* margin-top: 4vh; */
}

#FactDisplay .factsheet-table {
	width: 100%;
	background-color: white;
}
#FactDisplay .fact-outcome-td {
  background-color: var(--brand-color-1);
}

.whats-new-table td {
	padding-bottom: 3px;
	text-align: left;
	/* font-size: calc(11px + .25vw); */
	border-top: solid thin lightgray;
	font-size: 11pt;
	min-width: 0 !important;
}

.whats-new-table {
	width:100%;
	height: 100%;
}

.whats-new-table th {
	text-align: left;
	padding: 5px 1vw 2px;
}

.whats-new-table td {
	padding: 2px 1vw;
}

.whats-new-table-update {
	width:35%;
	vertical-align: top;
}

.whats-new-table-filler {
	width:25%;
	vertical-align: top;
}

.whats-new-table-days-ago {
	width:25%;
	/*padding-left: 5%;*/
	vertical-align: top;
}

#LandingPage .container {
	min-width: fit-content;
}