#data-viewer .thumbnail-pic {
	border: solid medium var(--brand-color-2) !important;
	max-width: 170px;
	transition: 0.5s;
}
/* 
#data-viewer .thumbnail-pic.expandable img:hover {
	width: 47vw;
	transition: 0.5s;
} */

#data-viewer-reactive-search {
  margin-top: 10px;
  margin-left: 25px;
}

#data-viewer-table-of-contents {
	margin-top: 10px;
	margin-left: 25px;
	border: solid thin var(--brand-color-1);
	color: black;
  border-radius: 20px;
  padding: 20px;
}

#data-viewer-table-of-contents a, #data-viewer-table-of-contents h2, #data-viewer-table-of-contents li::marker {
	color: var(--brand-color-2);
}

#data-viewer-table-of-contents a:hover, #data-viewer-table-of-contents h2:hover {
	color: var(--brand-color-1);
}

#data-viewer-table-of-contents .table-of-contents-caret {
	display: none;
}

#data-viewer-table-of-contents h4 {
  margin-bottom: 30px;
}

.csr-search-result {
	border: solid thin var(--brand-color-1);
  border-radius: 20px;
  margin-bottom: 25px;
  padding: 20px;
}

.csr-description {
  max-height: 400px;
	overflow-y: auto;
	text-align: left;
	/* border: solid thin var(--brand-color-2); */
  font-style: italic;
	margin-bottom: 15px;
}

.csr-action-button {
	color: var(--brand-color-1);
  background-color: white;
	border-color: var(--brand-color-1);
	/*font-weight: 600;*/
	border-radius: 30px;
  margin-top: 15px;
}
.csr-action-button:hover {
	color: white;
  background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
}

@media (max-width: 767px) {
	#data-viewer-table-of-contents {
		margin-top: 5px;
		margin-left: 0px;
		height: 35px;
		transition: 0.25s;
		-webkit-transition: 0.25s;
	}

	#data-viewer-table-of-contents .table-of-contents-caret, #data-viewer-table-of-contents h2 {
		display: inline;
	}

	#data-viewer-table-of-contents center {
		cursor: pointer;
	}

	#data-viewer-table-of-contents.expanded {
		height: 100vh;
		overflow: auto;
		z-index: 200;
		position: fixed;
		top: 0;
	}
}

.figure-table {
	/*margin: 15px;*/
	/*animation-name: fadeInDown;*/
	/*animation-duration: .5s;*/
	/*animation-delay: 0s;*/
	/*animation-fill-mode: both;*/
}


#data-viewer-table-of-contents .anticon {
  vertical-align: bottom !important;
}

.relevant-document-container {
  margin: 30px 0 5px 0;
}

.csr-picture-modal {
  /* height: 80vh; */
  width: 100%;
}

.csr-reactive-search-input {
  border-radius: 5px;
  font-size: 10pt !important;
  min-height: 0px !important;
  height: 32px !important;
  /*width: 20vw !important;*/
  padding: 0px;
  border-color: var(--brand-color-1) !important;
  color: var(--brand-color-1);
}
.csr-reactive-search-input > div {
	top: 1px;
}

#data-viewer-reactive-search .cancel-icon {
	margin-bottom: 0;
}

.csr-content-title {
	font-size: 16pt;
	/*border-radius: 15px !important;*/
}

.csr-content-title:hover {
	color: var(--brand-color-1);
	cursor: pointer;
}

.csr-content-title.active {
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
	font-weight: bold;
	/*border-radius: 15px 15px 0 0 !important;*/
}

.csr-section-item {
}
.csr-section-item.active {
	background-color: var(--brand-color-1) !important;
	border-color: var(--brand-color-1) !important;
}

.csr-subsection-item {
	/*border-color: var(--brand-color-1) !important;*/
}
.csr-subsection-item.active {
	background-color: var(--brand-color-1) !important;
	border-color: var(--brand-color-1) !important;
}