.study-center {
  margin: 10px 25px;
  min-height: 60vh;
}

.rbt-token {
	padding: 0.25rem 0.5rem;
	line-height: inherit;
}

.rbt-token-disabled {
	padding: 3px 10px 0px 10px !important;
}

.rbt-token-remove-button {
	padding: 0.25rem 0.5rem !important;
}

.study-center a {
  color: var(--brand-color-1);
}

.study-center .ant-card {
	color: rgba(0, 0, 0, 1);
}

#StudyCenterHome #studycenter-home-data {
  margin: 50px 20%;
}

#StudyList {
  margin-right: 25px;
  border: solid thin var(--brand-color-1);
  border-radius: 10px;
  padding: 15px 25px;
}

#StudyList .study {
  cursor: pointer;
	padding: 5px 5px;
}

#StudyList .study.active {
  background-color: var(--brand-color-2);
	border-radius: 5px;
  color: white;
}

#StudyList .study-route {
	cursor: pointer;
  margin: 5px 0px 5px 20px;
	font-size: 10pt;
}

#StudyList .study-route:hover {
	color: var(--brand-color-1)
}

#StudyList .study-route.active {
	/* border-bottom: solid 2px var(--brand-color-2); */
  /* background-color: var(--brand-color-2); */
  color: var(--brand-color-1);
	font-weight: bold;
}

#StudyList .study-search-input {
	margin-bottom: 10px;
}

#StudyList .study-search-input .ant-input-suffix {
	top: 50%;
}

.new-study-btn, .delete-study-btn {
  margin-left: 2px;
  display: inline-block;
  background-color: white;
  border-color: var(--brand-color-1);
  color: var(--brand-color-1);
  text-align: center;
  border-radius: 20px;
  min-width: 10vw;
  font-size: 14px;
}
.delete-study-btn > button {
  display: inline-block;
  background-color: white;
  border-color: var(--brand-color-2);
  color: var(--brand-color-2);
  text-align: center;
  border-radius: 20px;
  min-width: 10vw;
  font-size: 14px;
}

.delete-study-btn { 
	background-color: white;
  border-color: red;
  color: red;
}

.delete-study-btn:hover { 
	background-color: red;
	color: white;
  border-color: red;
}

.new-study-btn:hover {
  background-color: var(--brand-color-2);
  border-color: var(--brand-color-2);
  color: white;
}

#Study {
  padding: 15px 25px;
  min-height: 45vh;
}

#StudyContent {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 0 0 .25rem 0.25rem;
}

#StudyContent .ant-card-head h3 {
	margin-bottom: 0;
	color: white;
}
#StudyContent .ant-card-head {
	color: white !important;
	background-color: var(--brand-color-1);
}

#StudyContent div .ant-card-head-title {
	text-align: left;
}

#StudyContent .dropdown-item.active, .dropdown-item:active {
	background-color: var(--brand-color-1);
}

.study-key-date {
  margin-bottom: 5px;
  /* text-align: center; */
  
}

#kvm-evidence-statement-list .kvmtable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	display: inline-block;
}

#kvm-evidence-statement-list .kvm-column {
	background-color: #E2E7E9;
	color: #53646E;
}

#kvm-evidence-statement-list .kvm-id {
	transition: .25s;
	width: 2%;
}

#kvm-evidence-statement-list .kvm-message {
	text-align: left;
	/* padding: 0vh 2vw; */
}

#kvm-evidence-statement-list .kvm-label {
	height: 7vh;
	margin: 0;
	border-bottom: solid medium #EEEDED;
	transition: .25s;
	font-size: .75vw;
}

#kvm-evidence-statement-list .kvm-label:hover {
	background-color: #b4bfc5;
	color: white;
}

#kvm-evidence-statement-list .kvm-checkbox {
	transition: .25s;
	padding: 0;
	margin: 0;
	width: 10px;
}

#kvm-evidence-statement-list .kvm-checkbox-header {
	width: 5% !important;
}

#kvm-evidence-statement-list .kvm-label td {
	padding: 1vh .5vw .5vh .5vw;
}

#kvm-evidence-statement-list table td {
	text-align: left;
}

#kvm-evidence-statement-list table th {
	padding-left: 0;
	padding-right: 0;
}


#key-document-list .kvmtable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	/* display: inline-block; */
}

#key-document-list .kvm-column {
	background-color: #E2E7E9;
	color: #53646E;
}

#key-document-list .kvm-id {
	transition: .25s;
	width: 2%;
}

#key-document-list .kvm-message {
	text-align: left;
}

#key-document-list .kvm-label {
	height: 7vh;
	margin: 0;
	border-bottom: solid medium #EEEDED;
	transition: .25s;
	font-size: .75vw;
}

#key-document-list .kvm-label:hover {
	background-color: #b4bfc5;
	color: white;
}

#key-document-list .kvm-label td {
	padding: .5vh .5vw .5vh .5vw;
}

#key-document-list .kvm-label td div {
	margin: 2px;
}

#key-document-list table td {
	text-align: center;
}

#key-document-list table th {
	padding-left: 0;
	padding-right: 0;
}


.react-datepicker-popper {
  z-index: 10 !important;
}

.calendar-container {
	height: 80vh;
	margin-bottom: 25px;
}

/* div.rbc-event {
	padding: 0;
} */

/* .study-event {
	background-color: black;
	border-radius: 5px;
	width: 100%;
	padding: 2px 5px;
}

.communication-target-event {
	background-color: green;
	border-radius: 5px;
	width: 100%;
	padding: 2px 5px;
} */
.transparent {
	opacity: .5;
}
.loading-key-dates {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;
	top: 0;
	left: 0;
	border-radius: 20px;
}

.timeline-filter-actions, .timeline-study-list-modal {
	margin-top: 10px;
}

.timeline-filter-actions > div > button {
	padding: 0;
	margin-right: 15px;
}

.modal-action-btns {
  background-color: white;
  border-color: var(--brand-color-2);
  border-radius: 20px;
  color: var(--brand-color-2) !important;
}

.year {
	/* display: flex; */
  overflow: auto;
  text-align: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.month {
	margin: 5px 5px 15px 5px;
  /* display: block; */
}

.month-name {
	color: #000;
	font-weight: bold;
  cursor: pointer;
}

.day {
  /* border: solid thin #DDD; */
	display: inline-block;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
}

.date {
  padding: 0;
  border: solid thin #DDD !important;
  border-radius: 0px;
	width: 50px;
	height: 50px;
	background: white;
	border: none;
	outline: none;
}

.date.in-month:hover {
	cursor: pointer;
	color: black;
	background: #E9F6FF;
}

.today {
  font-weight: bold;
	color: black;
	background: #E9F6FF;
}

.date.prev-month,
.date.next-month {
	color: grey;
}

.marked-date {
  background-color: var(--brand-color-1);
  color: white;
  border-radius: 0;
}

.marked-date:hover {
  background-color: var(--brand-color-1) !important;
  color: white !important;
  border-radius: 0;
}

.date.in-month.marked-date.communication-targets {
  background-color: var(--brand-color-2);
  color: white;
  border-radius: 0;
}

.date.in-month.marked-date.communication-targets.key-dates {
  background-color: var(--brand-color-2);
  background: linear-gradient(-45deg, var(--brand-color-2) 50%, var(--brand-color-1) 50%);
  color: white;
  border-radius: 0;
}

.date.in-month.marked-date.communication-targets:hover {
  background-color: var(--brand-color-2) !important;
  color: white !important;
  border-radius: 0;
}

.event-popover-content {
  color: white;
  margin: 2px 0;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.rbc-agenda-table td {
  text-align: left;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  border-left: 1px solid #DDD;
}

#BasicInfo .ant-input[disabled], #CommunicationTarget .ant-input[disabled],
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice
{
	color: rgba(0, 0, 0, 1) !important;
}

.table.table-hover.table-bordered {
	margin-bottom: 0px;
}

.communication-target-item {
	padding: 5px;
	margin: 5px 0;
}

.communication-target-item-date{
	font-size: 9pt;
}

.communication-target-item:hover {
	cursor: pointer;
}

.communication-target-item.active {
	color: white;
	background-color: var(--brand-color-2);
	border-radius: 5px;
}

#KeyDates .ant-input-suffix, #ComunicationTargets .ant-input-suffix{
	top: 50%;
}

#AddStudy .field {
	margin-bottom: 1vh;
}

.criteria-text {
	padding: 1px;
	min-height: 0px;
	font-size: 14px;
	margin-left: 25px;
}
.criteria-text p {
	margin-bottom: 0;
}

.population-btns {
	min-width: 150px;
}

/*.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected,*/
/*.react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,*/
.react-datepicker__day--range-end, .react-datepicker__day--range-start,
.react-datepicker__day--range-end.react-datepicker__day--keyboard-selected,
.react-datepicker__day--range-start.react-datepicker__day--keyboard-selected {
	background-color: var(--brand-color-2) !important;
	color: white !important;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
	margin: 4px !important;
	padding: 4px;
}

.date-picker-month-selector {
	border-radius: 5px;
	font-size: 14px;
}

.date-picker-month-selector:hover {
	cursor: pointer;
}

.date-picker-button {
	background-color: white !important;
	color: black !important;
	border-color: gray !important;
}

.date-picker-button:hover {
	background-color: #e6e6e6 !important;
	transition: 0s !important;
}

.date-picker-button:first-of-type {
	/*border-radius: 2px 0 0 2px;*/
	border-radius: 5px;
	margin-right: 2px;
}

.date-picker-button:last-of-type {
	/*border-radius: 0 2px 2px 0;*/
	border-radius: 5px;
	margin-left: 2px;
}