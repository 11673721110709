/*
colors:
	light gray: rgba(143, 153, 160, 1)
	darker gray: rgba(122, 153, 172, 1)
	pink: var(--brand-color-2);
	yellow: rgba(195, 214, 37, 1)
	purple: var(--brand-color-2)
*/


/*--Search--*/
#search .search-result {
	/* border: solid thin var(--brand-color-2); */
	margin: 10px 25px;
}

#search .search-input-form {
	width: 100%;
}

#search .search-input-form .ant-input {
	height: 50px;
}

#search .search-input-form button {
	background-color: var(--brand-color-2);
}

#search .search-input-form button:hover {
	border-color: var(--brand-color-2);
}

#search .ant-switch {
	background-color: var(--brand-color-2);
}

#search .ant-switch-checked {
	background-color: var(--brand-color-2);
}

#search .searchResults th {
	color: white;
	background-color: var(--brand-color-2);
	border-bottom: solid medium var(--brand-color-2);
  position: sticky;
  top: 0;
  z-index: 1;
}


#search .thumbnail-pic {
	border: solid medium var(--brand-color-2) !important;
	float: none;
	margin-bottom: 10px;
}

#search .title {
	text-align: center;
}

#search .category-images {
	background-color: var(--brand-color-2);
	display: inline-block;
	min-width: 100px;
	margin-bottom: 10px;
}

#search .search-results-facts:hover {
	color: black;
}

#search .css-14aw7e8.ez0j50z1:hover {
	color: black;
	cursor: default;
}


#search {
	margin: 0 20px;
}

#search .searchInput {
	font-size: 1.25vw;
	font-size: 1.25vmax;
	margin-top: 2vh;
	width: 33%;
	height: 5vh;
	padding-left: 2vw;
}

#search .searchInput.success:focus {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
  box-shadow: 0 0 0px 2px rgba(30, 243, 58, 0.57) inset, 0 0 4px rgba(126, 239, 104, 0.6);
  outline: 0 none;
  border: none;
}

#search .searchInput.error:focus {
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(251, 15, 0, 0.98);
  box-shadow: 0 0 0px 2px rgba(251, 15, 0, 0.98) inset, 0 0 4px rgba(251, 15, 0, 0.98);
  outline: 0 none;
  border: none;
}

#search .searchBtn{
	outline: 0 none;
}
#search .searchBtn:hover{
	color: red;
	border-color: red;
}

#search .searchResult-text span {
	margin-right: 2vw;
}

#search .searchResults table {
	width: 100%;
	overflow: auto;
}

#search .searchResult-text {
	margin-top: 3vh;
	height: 5vh;
	width: 50vw;
	margin-left: 17.5%;
	margin-bottom: 2%;
}

@media (max-width: 500px) {
	#search .searchResult-text{
		font-size: 5px !important;
	}
}

#search .searchTabs {
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	font-family:  "Open Sans", "Helvetica", sans-serif;
	flex-flow: nowrap;
	list-style: none;
	width: 100%;
	margin: 0;
}

#search .searchTab {
	font-size: 1vw;
	font-size: 1vmax;
	-webkit-box-shadow: 2px 1px 10px darkgrey;
	box-shadow: 2px 1px 10px darkgrey;
	-webkit-transition: .1s;
	-webkit-transition: .2s;
	-o-transition: .2s;
	transition: .2s;
	cursor: pointer;
	margin-top: 2vh;
	color: black;
	margin-right: 2vw;
	width: 15vw;
	height: 8vh;
	background-color: transparent;
}

#search .searchTab:hover {
	box-shadow: 3px 2px 10px darkgrey;
	background-color: #e9e9e9;
}

#search .searchTab.selected {
	background-color: red;
	color: white;
}

#search .searchTab.selected:focus {
	outline: 0 none;
}

#search .table-header {
	color: rgba(143, 153, 160, 1);
	font-size: 1vw;
	font-size: 1vmax;
	font-weight: bolder;
	background-color: white;
	width: 100%;
	overflow: auto;
}

#search .table-header th {
	color: white;
	background-color: var(--brand-color-2);
	border: 2px solid var(--brand-color-2);
	padding: 10px;
}

#search .table-header tr {
	padding: 10px;
}

#search .result-row td {
	padding: 5px;
	border: 1px solid var(--brand-color-2);
}

#search .result-row {
	background-color: rgba(255, 255, 255, 0);
	transition: 0.5s;
	font-size: 1vw;
	font-size: 1vmax;
	height: 5vh;
}

#search .result-row:hover {
	background-color: rgba(143, 153, 160, 1);
	color: white;
}

#search .result {
	background-color: white;
}

.search-pdfs, .css-144a5ey.ez0j50z1 {
	margin-top: 15px;
	border: 2px solid rgba(122, 153, 172, 1) !important;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.8);
}

.search-pdfs a {
	color: rgba(143, 153, 160, 1);
	text-decoration: underline;
	word-wrap: break-word;
}

.search-pdfs .ant-btn, .css-144a5ey.ez0j50z1 .ant-btn {
	border-radius: 20px;
	background-color: white !important;
	color: var(--brand-color-2) !important;
}

.search-pdfs .ant-btn:hover, .css-144a5ey.ez0j50z1 .ant-btn:hover {
	color: white !important;
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2);
}

#search .ant-input-search-button {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
}

#search .search-pdfs .vertical-center-col {
	margin-top: 25%;
}

@media (max-width: 990px) {
	#search .search-pdfs .vertical-center-col {
		margin-top: 0;
	}
}


.search-input-form {
  width: 20vw;
  margin-top: 1.5%;
}

.search-input-form input {
	font-size: 20px;
}

.search-input-form button {
	height: 50px;
}


.reactive-search-pagination-container {
	margin: 25px;
}

.data-view-btn {
	color: var(--brand-color-2);
  background-color: white;
	border-color: var(--brand-color-2);
	/*font-weight: 600;*/
	border-radius: 30px;
}

.data-view-btn:hover {
	color: white;
  background-color: var(--brand-color-1) !important;
	border-color: var(--brand-color-1) !important;
}

.big-reactive-search-input {
	border-radius: 10px !important;
	border-color: var(--brand-color-1) !important;
	background-color: white !important;
}

.big-reactive-single-dropdown {
	margin-top: 5px !important;
}