/*-- Global Variables for changing branding colors across application --*/
:root {
	--brand-color-1: rgba(0, 57, 103, 1);
	--brand-color-2: rgba(240, 89, 35, 1);
	--brand-color-3: rgba(0, 57, 103, 1);
	--brand-color-1-transparent: rgba(0, 57, 103, 0.75);
	--brand-color-2-transparent: rgba(240, 89, 35, 0.75);
	--brand-color-3-transparent: rgba(0, 57, 103, 0.75);
}
/*  */

body {
	font-family:  "Open Sans", "Helvetica", sans-serif;
	color: rgba(0, 0, 0, 1);
}

#PasswordResetForm .ant-input-suffix {
  top: 50%;
}

body ::-webkit-scrollbar {
  width: 10px;
	border-radius: 20px;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: #7F7F7F;
	border-radius: 20px;
	margin-top: 4.5px;
	margin-bottom: 4.5px;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  /* background: rgb(228, 95, 58); */
  background: var(--brand-color-2);
	border-radius: 20px;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: var(--brand-color-2-transparent); 
}

#root {
	display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#watermark {
	position: fixed;
	right: 0;
	bottom: 0;
	color: black;
	text-align: right;
	opacity: 0.5;
	font-size: 45pt;
	font-weight: bold;
	padding: 0 25px 0;
}

#route-container {
	flex: 1;
}

.ant-input, .ant-select, .limiter, .ant-select-selection--multiple .ant-select-selection__choice {
  color: black;
}

.ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
	top: 30%;
}

.loading-animation {
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.loader-wrapper {
	height: 50px;
	margin-bottom: 45px;
}

.loader,
.loader:before,
.loader:after {
  background: var(--brand-color-2);
  -webkit-animation: load1 .75s infinite ease-in-out;
  animation: load1 .75s infinite ease-in-out;
	border-radius: 10px;
  width: 1em;
  height: 4em;
}
.loader {
	display: block;
  color: var(--brand-color-2);
  text-indent: -9999em;
  margin: 45px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
	-webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

#route-container {
	min-height: 100%;
	position: relative;
	/* padding-bottom: 200px; */
}

.brand-image-background {
	/* background-image: url("../images/1382_1920.jpg"); */
	/* background-image: url("https://www.rhythmtx.com/wp-content/themes/understrap-child/images/background/background-blue-gradient.png"); */
	/* background-repeat: no-repeat; */
	background-size: cover;
	/* box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); */
	border: solid thin var(--brand-color-1);
	background-color: #ebf8fd;
	/* background-color: #EBF9FC; */
	border-radius: 10px;
}

.brand-image-background-two {
	/* background-image: url("../images/1382_1920.jpg"); */
	/* background-repeat: no-repeat; */
	/* background-size: cover; */
	background-color: #ebf8fd;
	border-radius: 30px;
	box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5);
}

.full-view-height-with-padding {
	height: 75vh;
	padding: 2vh 1vw;
	margin: 2vh 1vw;
	overflow: auto;
}

.ant-modal-body-custom .ant-modal-body, .modal-body {
	/* background-image: url("../images/1382_1920.jpg"); */
	background-color: #ebf8fd;
	/* background-repeat: no-repeat; */
	/* background-size: cover; */
	/* box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.5); */
}

.ant-modal-title {
	font-weight: bold !important;
}

.ant-modal-body-custom .ant-modal-header, .ant-modal-body-custom .ant-modal-footer, .ant-modal-body-custom .ant-modal-title, .modal-header, .modal-footer {
	background-color: var(--brand-color-1);
	color: white;
}

.ant-modal-body-custom .ant-modal-header .ant-btn, .ant-modal-body-custom .ant-modal-footer .ant-btn, .ant-modal-body-custom .ant-modal-title .ant-btn, .modal-footer .ant-btn {
	background-color: white;
	border-color: var(--brand-color-1);
	border-radius: 20px;
	color: var(--brand-color-1) !important;
}

.ant-modal-body-custom .ant-modal-header .ant-btn:hover, .ant-modal-body-custom .ant-modal-footer .ant-btn:hover, .ant-modal-body-custom .ant-modal-title .ant-btn:hover,  .modal-footer .ant-btn:hover {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	color: white !important;
}

.ant-modal-close-x svg {
	fill: white;
}

.translucent-background-with-padding {
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
	font-family:  "Open Sans", "Helvetica", sans-serif;
	padding: 1vh 1vw;
	margin: 2vh 1vw;
	color: var(--brand-color-2);
	background-color: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(6px);
}

#background-image-left-bottom {
	width: 100vw;
	height: 100vh;
}

.brand-footer {
	background-color: var(--brand-color-1);
	color: white;
	/* border-top: 1px solid #E7E7E7; */
	border-top: solid var(--brand-color-1);
	text-align: center;
	padding: 20px;
	/*height: 150px;*/
	width: 100%;
	/* position: absolute; */
}

.brand-footer img {
	margin-top: 5px;
	width: 200px;
	height: auto;
	padding: 0 5px;
}

/* .brand-footer img:nth-of-type(1) {
	border-right: 1px solid white;
} */

.brand-footer p {
	margin-top: 20px;
	/* font-weight: bold; */
	font-size: 10pt;
}


.data-viewer-list{
	padding-right: 100px;
}

#area .indented-message {
	margin-left: 9%;
}

.swal-footer {
	text-align: center;
}

#myProgress {
	display: none;
  width: 100%;
  background-color: white;
}

#myBar {
  width: 0%;
  height: 15px;
  background-color: lightgreen;
}

.thumbnail-pic {
	/*float: left;*/
	text-align: center;
}

button.close {
	color: white;
	text-shadow: none;
	opacity: 1;
}
button.close:hover {
	color: white;
}

.supporting-description {
	/* width: 75%; */
	max-height: 400px;
	overflow-y: auto;
	text-align: left;
	border: solid thin var(--brand-color-2);
	border-radius: 10px/10px;
	padding-left: 10px;
	padding-bottom: 5px;
	padding-top: 5px;
}


.result-list-container .css-14aw7e8.ez0j50z1:hover .supporting-description {
	color: var(--brand-color-1);
}

/* REACIVE SEARCH image: ATTRIBUTE */
.ez0j50z0 {
	border: solid medium #013a87;
}

/* REACTIVE SEARCH a TAG */
.css-1arb8cv:hover {
	cursor: auto;
	color:black;
}

/* REACTIVE SEARCH a TAG FOR FACTS */
.css-144a5ey:hover{
	cursor: auto;
	color: black;
}

.evidence-summary-icons img {
	padding: 0 5px;
}

.marked {
	background-color: var(--brand-color-1);
	color: white;
}

mark {
	background-color: var(--brand-color-2) !important;
	color: white;
}

/* body{
	margin:0;
	color: #57656D;
	font-family:  "Open Sans", "Helvetica", sans-serif;
} */

table th{
	padding-left: 1vw;
	padding-right: 1vw;
	text-align: center;
}

table td {
	text-align: center;
}

table td input {
	text-align: center;
}


.ant-switch {
	background-color: var(--brand-color-1);
}

.ant-switch-checked {
	background-color: var(--brand-color-2);
}


.evidence-btn {
	color: var(--brand-color-1);
	border-color: var(--brand-color-1);
	width: 100%;
	margin-top: 0.25vh;
	margin-bottom: 0.25vh;
	border-radius: 30px;
	font-family:  "Open Sans", "Helvetica", sans-serif;
}

.evidence-btn:hover, .evidence-btn:active, .evidence-btn:focus {
	color: white;
	border-color: var(--brand-color-2);
	background-color: var(--brand-color-2);
}



.pagination-button, .pagination-navigation-button {
	transition: .25s;
	padding: 2px;
	font-size: 10pt;
	width: 27px;
	margin: 0 2px;
	background-color: white;
	color: var(--brand-color-1) !important;
	border-color: var(--brand-color-1) !important;
}

.reactive-search-pagination-button.pagination-button-active, .reactive-search-pagination-button {
	width: 35px;
}

.pagination-navigation-button > span {
	vertical-align: middle;
}

.pagination-button:hover, .pagination-navigation-button:hover {
	font-size: 10pt;
	border-color: var(--brand-color-2-transparent) !important;
	background-color: var(--brand-color-2-transparent) !important;
	color: white !important;
}

.pagination-button-active {
	font-size: 10pt;
	padding: 2px;
	width: 27px;
	margin: 0 2px;
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
	color: white !important;
}


.kvm-label:hover .kvm-id, .kvm-label:hover .kvm-checkbox {
	background-color: rgba(158,172,180, 0.8);
	color: white;
}


.ant-popover-inner-content a {
	color: #718692;
	text-decoration: underline;
}

.ant-popover-inner-content .ant-btn {
	border-radius: 20px;
}

.ant-popover-inner-content .ant-btn:hover {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	color: white;
}


.block{
	display: block;
	margin-bottom: 5vh;
	border-bottom: solid thin black;
}

.fact-view-button {
	min-width: 100px;
}

.required {
	color: red;
}

div .ant-card-head-title{
	text-align: center;
}


/*div .pdf-thumbnail img, .thumbnail-pic img {*/
/*	border: solid medium var(--brand-color-2) !important;*/
	/* cursor: pointer; */
/*}*/


thead tr:nth-child(1) th{
	color: white;
	background-color: var(--brand-color-2);
	border-bottom: solid medium var(--brand-color-2);
  top: 0;
  z-index: 1;
}

thead tr:first-child th:first-child {
	border-radius: 5px 0 0 0;
}

thead tr:first-child th:last-child {
	border-radius: 0 5px 0 0;
}

div .Select-menu-outer{
	z-index:2;
}

#context-div{
	margin-top:15px;
	margin-bottom:15px;

	animation-duration: .5s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 100%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.css-1vy3jc0.e165j7gc0, .css-ktp7i6.e165j7gc0 {
	color: rgba(0, 0, 0, 0.6) !important;
	border: 1px solid #718692 !important;
	background-color: white;
}

 .active.css-ekaund.e165j7gc0 {
	color: white !important;
	font-weight: bold !important;
	border: 1px solid #718692 !important;
	background-color: #718692 !important;
}

 .active.css-ekaund.e165j7gc0:hover {
	color: white !important;
	font-weight: bold !important;
	border: 1px solid #5f727c !important;
	background-color: #5f727c !important;
}

.css-1vy3jc0.e165j7gc0:hover, .css-ktp7i6.e165j7gc0:hover {
	color: white !important;
	font-weight: bold !important;
	border: 1px solid #EB6E38 !important;
	background-color: #EB6E38 !important;
}


#popup .ant-btn {
	color: var(--brand-color-1) !important;
	background-color: white !important;
	border-color: var(--brand-color-1) !important;
}

#popup .ant-btn:hover {
	color: white !important;
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
	background-color: var(--brand-color-2) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

.ant-checkbox-wrapper:active .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

.ant-checkbox-wrapper-checked:active .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

/* Reactive Search pagination buttons */
.css-4kq8mq.e165j7gc0 {
	color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	background-color: white;
}

.css-4kq8mq.e165j7gc0:hover, .css-4kq8mq.e165j7gc0:focus, .active.css-fdtpv0.e165j7gc0 {
	border-color: var(--brand-color-1);
	background-color: var(--brand-color-1);
	color: white;
}

#FilterSideBar {
	margin: 10px 25px;
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
	padding: 0 25px;
}

#FilterSideBar h4 {
	margin-top: 15px;
}

#FilterSideBar h5 {
	margin-top: 15px;
}

#FilterSideBar input {
	/*margin-bottom: 25px;*/
	border-color: var(--brand-color-1)
}
#FilterSideBar .ant-input-suffix {
	top: 50%;
	/*border-color: var(--brand-color-1)*/
}

#FilterSideBar input::placeholder {
	color: gray;
}

#FilterSideBar .filter-categories {
	margin: 15px 0;
}

#FilterSideBar .filter-categories .ant-checkbox-wrapper {
	color: black;
	display: block;
	margin-bottom: 5px;
	margin-left: 0px;
	font-size: 10pt;
}

#FilterSideBar .filter-title {
	margin-right: 10px;
}

#FilterSideBar .filter-action-btns,
.filter-action-btns .filter-select-all-btn,
.filter-action-btns .filter-clear-all-btn,
.timeline-filter-action-btns .filter-select-all-btn,
.timeline-filter-action-btns .filter-clear-all-btn {
	display: inline-block;
	font-size: 11pt;
	/* margin-left: 5px; */
}

.filter-action-btns .filter-select-all-btn,
.filter-action-btns .filter-clear-all-btn, .timeline-filter-action-btns {
	cursor: pointer;
	color: black;
}

.filter-action-btns .filter-select-all-btn:hover,
.filter-action-btns .filter-clear-all-btn:hover,
.timeline-filter-action-btns .filter-select-all-btn:hover,
.timeline-filter-action-btns .filter-clear-all-btn:hover {
	color: var(--brand-color-2);
	transition: .25s;
}

.filter-action-btns .filter-clear-all-btn {
	margin-left: 7px;
}

.timeline-filter-action-btns {
	margin-top: 5px;
}

.timeline-filter-action-btns .filter-clear-all-btn {
	margin-right: 10px;
}

.categories-btns {
	display: inline-block;
	border-color: var(--brand-color-2);
	border-radius: 20px;
	font-size: 11pt;
	color:var(--brand-color-2);
}

.categories-btns.clear-all {
	margin-left: 5px;
}

.ant-input, .ant-select-selection--single, .ant-select-selection--multiple {
	border-color: var(--brand-color-1);
}

.ant-select-arrow .ant-select-arrow-icon svg, .ant-radio-wrapper, .ant-checkbox-wrapper {
	color: black;
}

.limiter .ant-select-selection--single {
	border-color: black;
}

@media (min-width: 992px){
	.modal-lg, .modal-xl {
		max-width: 1000px;
	}
}

.rbt-input {
	border-color: var(--brand-color-1);
	padding: 3px 5px 5px;
	font-size: 14px;
}
.rbt-menu {
	font-size: 14px !important;
}
.rbt-highlight-text {
	color: white !important;
}
.rbt-input[disabled] {
	background-color: #F5F5F5 !important;
}
.rbt-input ::placeholder {
	color: rgba(0,0,0,.25) !important;
	padding: 5px;
}
.rbt-input:hover {
	border-color: var(--brand-color-1);
}
.rbt-input[disabled]:hover {
	border-color: #d9d9d9;
}

.rbt-token {
	position: relative;
	float: left;
	max-width: 99%;
	margin-right: 4px;
	padding: 3px 0 0 10px;
	overflow: hidden;
	color: rgba(0, 0, 0);
	background-color: #fafafa;
	border: 1px solid #e8e8e8;
	border-radius: 2px;
	cursor: default;
	font-size: 14px;
	font-variant: tabular-nums;
	/*font-weight: bold;*/
	transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.rbt-token-active {
	background-color: var(--brand-color-2);
	color: white;
}
.rbt-token .rbt-token-remove-button {
	padding: 0.25rem 0.5rem;
	margin-bottom: -3px;
}
.field p {
	margin: 0;
}

.custom-btn {
	color: var(--brand-color-1);
  background-color: white;
	border-color: var(--brand-color-1);
	/*font-weight: 600;*/
	border-radius: 30px;
	min-width: 7vw;
}

.loading {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 5;
	top: 0;
	left: 0;
	border-radius: 20px;
}

.ant-select-selection:hover, .ant-input:hover, .rbt-input:hover {
	border-color: var(--brand-color-2);
}

.ant-radio-inner::after {
	background-color: var(--brand-color-2);
}

.ant-radio-checked .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner, .ant-radio:hover {
	border-color: var(--brand-color-2);
}

.alert-wrapper {
	margin-top: 10px;
}

.ant-btn:active, .ant-btn.active, .ant-btn:hover, .ant-btn:focus {
	color: white;
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	transition: .25s !important;
}

.ant-btn-primary {
	background-color: var(--brand-color-1);
	border-color: var(--brand-color-1);
}

.react-datepicker__day--selected {
	background-color: var(--brand-color-2) !important;
}

.form-inline .custom-select, .form-inline .input-group {
	box-shadow: none;
}


.delete-btn {
	background-color: white;
  border-color: red;
  color: red;
}

.delete-btn:hover {
	background-color: red;
	color: white;
  border-color: red;
}

.ant-select-disabled {
	color: rgba(0, 0, 0, 0.25);
}