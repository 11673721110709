#IndicationContainer {
  background-color: rgba(239, 248, 252);
  height:100vh;
}

#IndicationContainer .welcome h1, #IndicationContainer .welcome h5 {
  text-align: center;
  color: var(--brand-color-2);
}

#IndicationContainer .welcome {
  padding-top: 10vh;
}

#IndicationContainer .welcome h1 {
  font-size: 45pt;
  font-weight: bolder;
  width: 100%; 
  text-align: center; 
  border-bottom: .4rem solid var(--brand-color-1);
  line-height: 0.1em;
  /* margin: 10px 0 20px;  */
}

#IndicationContainer .welcome h1 span {
  background-color: rgba(239, 248, 252);
}
#IndicationContainer .welcome h1::after {
  content: '';
  display: block;
  border-bottom: .4rem solid var(--brand-color-3);
  margin-bottom: -6px;
  margin-left: 10vw;
  max-width: 300px;
}

#IndicationContainer .logout-btn {
  font-size: 18pt;
  color: var(--brand-color-1);
}

#IndicationContainer .logout-btn:hover {
  color: var(--brand-color-2);
  text-decoration: none;
}

.indications .pagination-container {
  margin-top: 100px;
  width: 75%;
  margin-left: 12.5%;
}

.indication-pagination-navigation-button {
  display: inline-block;
  margin-top: 7vh;
}
.indications .col {
  padding: 0 !important;
}
.indication-pagination-button {
  border-radius: 15px;
  display: inline-block;
  height: 20vh;
  line-height: 14vh;
  width: 100%;
	padding: 25px 10px 0;
	font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  transition: .5s;
	margin: 1vh 5px;
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
	color: white !important;
  cursor: pointer;
}
.indication-pagination-button-active {
  border-radius: 15px;
  display: inline-block;
  height: 20vh;
  line-height: 14vh;
  width: 100%;
  padding: 25px 10px 0;
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  transition: .5s;
  margin: 0 5px;
  background-color: var(--brand-color-1) !important;
  border-color: var(--brand-color-1) !important;
  color: white !important;
  cursor: pointer;
}

.indication-pagination-button-text {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.indication-pagination-navigation-button > span {
	vertical-align: middle;
}

.indication-pagination-button:hover {
	/* font-size: 15pt; */
	border-color: var(--brand-color-2-transparent) !important;
	background-color: var(--brand-color-2-transparent) !important;
	color: white !important;
}

.small-circle {
  height: 25px;
  width: 25px;
  margin-top: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.large-circle {
  height: 35px;
  width: 35px;
  background-color: var(--brand-color-2-transparent);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.indications .step {
  cursor: pointer;
  font-size: 35px;
  color: var(--brand-color-2-transparent);
}

.indications .jump {
  font-size: 25px;
  cursor: pointer;
  margin-top: 10px;
  color: var(--brand-color-1-transparent);
}