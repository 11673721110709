.user-management-container {
	margin-bottom: 25px;
	font-family: "Open Sans", "Helvetica", sans-serif;
}

.user-filters {
	margin: 10px 25px;
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
	padding: 0 25px 15px;
}

.user-filters h4 {
  margin-top: 15px;
}

.user-filters-btns {
  margin-bottom: 15px;
	display: flex;
	flex-direction: column;
}

.user-filters-btns Button {
  margin: 5px 0 5px 0;
}

.user-filters-btns Button:focus {
	background-color: var(--brand-color-2);
	color: white;
}

.user-filters-btns Button:hover {
	background-color: var(--brand-color-2);
	color: white;
}

.filter-slctd {
	background-color: var(--brand-color-2);
	color: white;
}

#user-list {
  margin-right: 25px;
  margin-top: 10px;
  overflow-y: auto;
	/* height: 80vh; */
	text-align: center;
}

.user-card {
  color: var(--brand-color-2);
  border: solid thin var(--brand-color-2);
	background-color: rgba(255, 255, 255, 1);
	backdrop-filter: blur(6px);
  margin-bottom: 2vh;
  margin-left: 2%;
  margin-right: 2%;
	border-radius: 20px;
	transition: 1s;
	text-align: center;
}

.user-card-text {
	margin: 10px 15px 10px 15px;
}

.user-card-title {
	text-decoration: underline;
	font-weight: bold;
}

.current-user-text {
	position: absolute;
	top: 5px;
	left: 4px;
	transform: rotate(-45deg);
	color: rgba(0, 0, 0, 0.24)
}

#user-list-table {
	width: 100%;
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 60vh;
	font-size: 10pt;
}

#user-list-table thead {
	position: sticky;
  top: 0;
  z-index: 1;
	font-weight: bolder;
	color: white;
	background-color: var(--brand-color-2);
	padding: 10px;
	text-align: center;
}

#user-list-table thead > tr > td > span {
	cursor: pointer;
}

#user-list-table td.centered {
	text-align: center;
}

#user-list-table td {
	text-align: left;
	padding: 10px;
}

#user-list-table .user-row.current-user {
	color: var(--brand-color-1);
}

#user-list-table .user-row {
	border-bottom: solid medium #EEEDED;
	font-size: 12px;
	overflow: auto;
	color: black;
}

#user-list .anticon {
	vertical-align: 0em;
}


.search-input {
	margin-bottom: 10px;
}

.user-filters .search-input .ant-input-suffix {
	top: 50%;
}
