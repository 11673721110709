/*--EGVD KVI--*/
#KeyValueInterface {
	/* margin: 2vh 1vw; */
	margin-bottom: 25px;
	font-family:  "Open Sans", "Helvetica", sans-serif;
}

/*--EGVD KVG--*/
#KVG {
	padding: 0 10px 0 0;
}

#KVG .kvm-group-loader {
  margin-top: 25vh;
  text-align: center;
  -webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

#KVG .group-button {
	font-size: 1.5vh;
  padding: 1.5vh 1vw;
	text-align: left;
	box-shadow: 2px 2px 10px rgba(0, 0 ,0, 0.5);
	-webkit-transition: .1s;
	transition: .2s;
	cursor: pointer;
	margin: 0 5px 2vh 5px;
	border-radius: 10px;
	/* letter-spacing: 0.2vw; */
}


#KVG .group-button.inactive {
	color: rgba(0,0,0,1);
	background-color: white;
}

#KVG .group-button.active {
  background-color: var(--brand-color-2);
  color: white;
}

#KVG .group-button:hover {
	box-shadow: 5px 5px 10px rgba(0, 0 ,0, 0.5);
	background-color: var(--brand-color-2);
  color: white;
}


/*-- Key Value Messages (in KVI) --*/

#kvm {
	/* padding: 30px 10px; */
	padding-top: 2%;
  margin-right: 25px;
  margin-top: 10px;
  overflow-y: auto;
	height: 80vh;
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
	background-color: #ebf8fd;
}

#kvm .group-button button {
  margin: 1vh 1vw;
  /* font-size: 1.1vw; */
	/* font-size: 1.1vmax; */
}

#kvm .message {
  display: block;
  /*background-color: #2F5597;*/
  color: white;
}

/* width */
body ::-webkit-scrollbar {
  width: 10px;
	border-radius: 20px;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: #7F7F7F;
	border-radius: 20px;
	margin-top: 12.5px;
	margin-bottom: 12.5px;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  /* background: rgb(228, 95, 58); */
  background: var(--brand-color-2);
	border-radius: 20px;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: var(--brand-color-2-transparent); 
}
/*-- More Key Value Message stuff --*/

.message {
  color: var(--brand-color-2);
  border: solid thin var(--brand-color-2);
	background-color: rgba(255, 255, 255, 1);
	/*backdrop-filter: blur(6px);*/
  margin-bottom: 2vh;
  /* width: 90%; */
  margin-left: 2%;
  margin-right: 2%;
	border-radius: 16px;
	/*transition: 1s;*/
}

.message.editable {
	border-color: var(--brand-color-1);
	border-style: dashed;
}
.message.editable:hover {
	cursor: grab;
}

.message.editable:active {
	cursor: grabbing;
}

.message .number-container {
	/*margin-left: 15px;*/
	text-align: center;
}

.message .number {
	font-size: 4vw;
  font-size: 3.5vmax;
  font-weight: 800;
	height: auto;
	text-align: center;
	/* margin-left: 10px; */
	/* letter-spacing: 2px; */
	background: -webkit-linear-gradient(left, var(--brand-color-2), var(--brand-color-1));
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.message .text {
  display: inline-block;
	/* padding-left: 55px; */
	/* font-size: calc(10px + 0.5vw); */
	 font-size: 10pt;
  margin: 1vh 0;
	color: var(--brand-color-1);
}

.message .goToPC {
  cursor: pointer;
  margin-top: 1vh;
	width: 10vh;
	height: auto;
}

.message .arrow {
	display: none;
}

.message .line {
  margin: 0;
  padding: 0;
	height: 100%;
}

.message .bottom {
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.message .collapse {
  background: var(--brand-color-2);
  border: none;
}

.message .collapse:last-child {
  border: none;
	border-radius: 0 0 60px 60px;
}

.message .ant-collapse > .ant-collapse-item {
  border:none;
	border-radius: 0 0 15px 15px;
}

.message .anticon.anticon-down {
	display: inline-flex;
}

.message .heading {
  color: white;
  font-size: 9pt;
	padding: 0;
	margin: 0;
}

.message .slideframe {
  width: 100%;
  height: 66vh;
}

.message .summary-list {
  list-style-type: disc;
  margin-left: 1vw;
	font-family:  "Open Sans", "Helvetica", sans-serif;
  color: black;
}

/* .message .ant-collapse:nth-of-type(1) {
	border-top: solid thin white;
} */

.message .ant-collapse:nth-of-type(2) {
	border-top: solid thin white;
}

.ant-collapse {
	border: none !important;
}

.message .ant-collapse-header {
	background: rgba(0, 0, 0, 0)
}

.message .ant-collapse-content .ant-collapse-content-active {
	border-radius: 50px;
}

.message .ant-collapse:last-child {
	border-radius: 0 0 15px 15px;
}

.message .message-slides .ant-collapse-item {
	border-radius: 0;
}

.message .ant-collapse > .ant-collapse-item:last-child, .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
	padding: .25vh .25vw;
	background-color: var(--brand-color-2);
	border-radius: 0 0 15px 15px;
}

.message .ant-collapse-item .ant-collapse-content-active {
	border-radius: 0 0 15px 15px;
}

.message .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
	display: none;
}

.evidence-statement {
	margin-bottom: 15px;
	/* margin-left: 20px; */
	/* border: solid thin var(--brand-color-2); */
	background-color: white;
	border-radius: 20px;
}

.evidence-statement:last-child {
	margin-bottom: 0px;
}

.message .kvm-collapse .ant-collapse-content-box {
	background-color: var(--brand-color-2);
}
.message .kvm-collapse .ant-collapse-content {
	border-top: none;
}

#message-categories {
  margin-top: 1vh;
  margin-left: 1vw;
  margin-right: 1vw;
	float: right;
}

#message-categories .image {
  width: 1.25vw;
  margin-right: .5vw;
	position: absolute;
	top: 1px;
	bottom: 1px;
}

#message-note {
	cursor: default;
	/* height: 40vh; */
}

#message-note .note {
	width: 80%;
	height: 40vh;
	margin-left: 10%;
	cursor: default;
}

#message-note .note .quill {
	height: 75%;
}


.kvm-show-btn {
	margin-top: .5vw;
	/* margin-left: .5vw; */
	margin-right: .5vw;
	min-width: 100px;
	color: var(--brand-color-1);
	border-color: var(--brand-color-1);
	border-radius: 100px;
	background-color: rgba(255, 255, 255, 1);
  float:right;
}

.kvm-show-btn:hover {
	color: var(--brand-color-2);
	border-color: var(--brand-color-2);
}

.kvm-view-evidence-btn {
  border: none;
  text-align: left;
  padding: 0px;
	height: 0;
}
.kvm-view-evidence-btn:hover {
  background-color: inherit;
  transition: 0s;
  color: rgba(0, 0, 0, 0.65) !important;
}


.ovp {
	color: var(--brand-color-1) !important;
  border: solid 2px var(--brand-color-2) !important;
	background-color: rgba(255, 255, 255, 1);
	backdrop-filter: blur(6px);
  margin-bottom: 2vh;
	padding-top: 0.5vw !important;
	/*padding-bottom: 0.5vw !important;*/
  /* width: 90%; */
	/*padding: 10px;*/
  margin-left: 2%;
  margin-right: 2%;
	border-radius: 20px;
	transition: .25s;
	font-size: 10pt;
}

.ovp h1, .ql-editor h1 { font-size: 32pt !important; color: var(--brand-color-1); }
.ovp h2, .ql-editor h2 { font-size: 26pt !important; color: var(--brand-color-1); }
.ovp h3, .ql-editor h3 { font-size: 22pt !important; color: var(--brand-color-1); }
.ovp h4, .ql-editor h4 { font-size: 20pt !important; color: var(--brand-color-1); }
.ovp h5, .ql-editor h5 { font-size: 18pt !important; color: var(--brand-color-1); }
.ovp h1, .ql-editor h1, .ovp h2, .ql-editor h2, .ovp h3, .ql-editor h3, .ovp h4, .ql-editor h4, .ovp h5, .ql-editor h5 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}

.quill {
	margin: 10px 0;
}

.ql-toolbar {
	border-radius: 20px 20px 0 0;
	border-color: var(--brand-color-1) !important;
	background-color: white;

}

.ql-container {
	border-radius: 0 0 20px 20px;
	border-color: var(--brand-color-1) !important;
	min-height: 150px;
	background-color: white;
}

.ql-editor {
	min-height: 200px;
	font-size: 10pt;
	font-family: "Open Sans", "Helvetica", sans-serif
}

.new-ovp-button {
	float: right;
	margin: 0 15px 15px 0;
	background-color: white;
  border-color: var(--brand-color-2);
  color: var(--brand-color-2);
  text-align: center;
  border-radius: 20px;
  min-width: 10vw;
  font-size: 14px;
}

/*.evidence-container {*/
/*	height: 50vh;*/
/*	overflow: auto;*/
/*}*/

#newkvm .evidencetable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	display: inline-block;
	width:100%
}

#newkvm .evidencetable .label {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	padding: 1vh 1vw 1vh 1vw;
	/*display: inline-block;*/
	/*font-size: 10pt;*/
	margin-bottom: 0.5rem;
}

#newkvm .evidencetable .kvm-message {
	font-size: 10pt;
	margin: 0.5rem 0;
	text-align: left;
}

#kvm-evidence-statement-list .kvmtable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	display: inline-block;
}

#kvm-evidence-statement-list .kvm-column {
	background-color: #E2E7E9;
	color: #53646E;
}

#kvm-evidence-statement-list .kvm-id {
	transition: .25s;
	width: 2%;
}

#kvm-evidence-statement-list .kvm-message {
	text-align: left;
	/* padding: 0vh 2vw; */
}

#kvm-evidence-statement-list .kvm-label {
	height: 7vh;
	margin: 0;
	border-bottom: solid medium #EEEDED;
	transition: .25s !important;
	font-size: .75vw;
	color: var(--brand-color-1);
}

#kvm-evidence-statement-list .kvm-label:hover {
	background-color: #b4bfc5;
	color: white;
}

.delete-modal:hover {
	background-color: inherit !important;
	color: inherit !important;
}

#kvm-evidence-statement-list .kvm-checkbox {
	transition: .25s;
	padding: 0;
	margin: 0;
	width: 10px;
}

#kvm-evidence-statement-list .kvm-checkbox-header {
	width: 5% !important;
}

#kvm-evidence-statement-list .kvm-label td {
	padding: .5vh .5vw .5vh .5vw;
	text-align: center;
}

#kvm-evidence-statement-list table td {
	text-align: center;
}

#kvm-evidence-statement-list table th {
	padding-left: 0;
	padding-right: 0;
}

.ant-popover-open {
	z-index: 9999999;
}

#newkvm .title {
	color: var(--brand-color-1);
	margin-bottom: 5px;
}

#newkvm .new_evidence {
	margin-bottom: 10px;
}

.kvm-extra-action-buttons {
	margin: 10px 25px;
	/*border: solid thin var(--brand-color-1);*/
	border-radius: 10px;
	/*padding: 0 25px;*/
}

.kvm-extra-action-buttons > .custom-btn {
	width: 100%;
	min-width: 0px;
}

.kvm-show-btn.ant-btn-background.ovp-btn .anticon {
	vertical-align: 0.125em;
}