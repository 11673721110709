.filter-management {
  margin: 10px 25px;
}

.filter-management-content {
  margin: 0 8%;
  border: solid thin var(--brand-color-1);
  border-radius: 20px;
  padding: 15px;
  text-align: center;
  min-height: 70vh;
}

.module-input {
  width: 30%;
}

#CategoryTypeList {
  margin-top: 25px;
  width: 60%;
  font-size: 11pt;
  text-align: left;
}

/*.list-item {*/
/*  margin: 5px 0;*/
/*  padding: 5px 10px;*/
/*  cursor: pointer;*/
/*  !*border: 1px solid rgba(0,0,0,.1);*!*/
/*  border: 1px solid var(--brand-color-1);*/
/*  border-radius: 10px;*/
/*  background-color: white;*/
/*  transition: .25s;*/
/*}*/
.list-item.movable:hover {
  cursor: pointer;
}
.list-item.movable {
  border: 1px solid var(--brand-color-2);
  transition: .25s;
  cursor: grab !important;
}
.category.movable .anticon {
  vertical-align: 0.125em;
}
.list-item:hover {
  background-color: rgba(0,0,0,.1);
  transition: .25s;
}
.list-item.category-type:hover {
  background-color: inherit;
  cursor: default;
}
.list-item.active {
  background-color: var(--brand-color-2) !important;
  border-color: var(--brand-color-2) !important;
  color: white;
}

.action-btn:hover {
  cursor: pointer !important;
  color: var(--brand-color-2);
  transition: .25s;
}

#CategoryTypeList .ant-input-suffix {
  top: 50%;
}