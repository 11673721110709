/*--EGVD Login--*/
#loginScreen {
	height: 100%;
	width: 100%;
}
.home-loader {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 33vh 25vw 0 25vw;
	width: 50vw;
	font-size: 18px;
	/* letter-spacing: 1px; */
	position: relative;
}

.home-loader-text {
	margin-top: 10px;
}

@media (max-width: 650px) {
	.home-loader{
		width: 60vw;
		margin-left: 20vw;
	}
}

#loginScreen #sidebar {
	position: fixed;
	top: 0;
	left: 0;
	width: 25%;
	height: 100%;
	background-color: rgba(255,255,255, 0.9);
	border-radius: 2px / 2px;
  z-index:10;
}

#loginScreen .ant-input-affix-wrapper .ant-input-prefix, #loginScreen .ant-input-affix-wrapper .ant-input-suffix {
  top: 50%;
}

#loginScreen .email-field {
	width: 68%;
	margin-left: 16%;
	margin-bottom: 10px;
}

#loginScreen .email-field:focus {
	outline: 0 none;
}

#loginScreen .password-field {
	width: 68%;
	margin-left: 16%;
}

#loginScreen .password-field {
	outline: 0 none;
}

#loginScreen #background {
  /*background-image: url('../../static/images/Mesh_IEP_Background.svg');*/
  background-image: url('../../static/images/MESH_IEP_Background_Blue_v03.png');
  height: 100vh;
	width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: rgb(40, 50, 55); */
  background-color: #ebf8fd;
}

#loginScreen #background #logo {
	position: absolute;
	width: 400px;
	margin: 25% 50% 25% 60%;
	background-repeat: no-repeat;
}

#genesis-lightbulb {
  /*margin: auto;*/
  /*text-align: center;*/
  /*width: 2.5%;*/
  position: absolute;
  right: 25%;
  top: 25%;
  /*border: 3px solid #73AD21;*/
  padding: 10px;
}

#genesis-lightbulb-logo {
	width: 500px;
}


#loginScreen .ant-input-affix-wrapper .ant-input-prefix {
	left: 6px !important;
}

#loginScreen .input-icons {
	color: rgba(0,0,0,.25);
	left: 6px;
}

#loginScreen .login-inputs {
	margin-top: 1vh;
}

#loginScreen .login-inputs > span > input {
	background: transparent;
	border: none;
	border-bottom: 1px solid #718692 !important;
	border-radius: 0;
}

#loginScreen .login-footer {
	position: absolute;
	bottom: 0;
	height: 10vh;
	padding-top: 16px;
	text-align: center;
	vertical-align: center;
	width: 100%;
	background-color: var(--brand-color-1);
}


#loginScreen .login-logo {
	/*display: block;*/
	width: calc(180px + 10%);
	max-width: 200px;
	position: relative;
	margin: -1.5vh auto calc(-14vh + 90px);
}

#loginScreen .login-egvd {
	color: white;
	text-align: center;
	margin-top: 20px;
}

#loginScreen .login-rx {
	/* color: rgba(95, 37, 159, 1); */
	color: var(--brand-color-1);
}

@media (max-width: 1400px) {
	#loginScreen #sidebar {
		width: 32%;
	}
}

@media (max-width: 1080px) {

	#LoginSideBar .form-action {
		padding-bottom: 0 !important;
	}

	#loginScreen #sidebar {
		position:fixed;
		top: 10%;
		left: 30%;
		width: 40%;
		height: 80%;
	}

	#loginScreen .login-inputs {
		margin-top: 0;
	}

	#LoginSideBar .login-error{
		text-align: center;
		margin-bottom: 3px;
	}

	#loginScreen .login-egvd {
		margin-top: 15px;
	}


	#LoginSideBar .registerButtons {
		height: 25px;
		margin-bottom: 16px !important;
	}

	#LoginSideBar .loginButtons {
		height: 25px;
		margin-bottom: 16px !important;
	}

	#loginScreen #background #logo {
		display: none;
	}

}

@media (max-width: 980px) {
	#loginScreen #sidebar{
		position: fixed;
		top: 10%;
		left: 32.5%;
		width: 40%;
		height: 80%;
	}
}

@media (max-width: 780px) {
	#loginScreen #sidebar{
		position:fixed;
		top: 10%;
		left: 25%;
		width: 50%;
		height: 80%;
	}
}

@media only screen and (max-width: 500px) {

	#loginScreen #sidebar{
		height: 100% !important;
		width: 100% !important;
		left: 0 !important;
		top: 0 !important;
	}

}

@media only screen and (max-width: 445px) {
	#loginScreen .login-logo {
		display: none;
	}

	#loginScreen .login-egvd {
		margin-top: 5px;
	}
}

@media only screen and (max-height: 760px) and (max-width: 760px) {

	#loginScreen #sidebar{
		height: 100% !important;
		width: 100% !important;
		left: 0 !important;
		top: 0 !important;
	}
}

@media only screen and (max-height: 550px) {
	#loginScreen #sidebar{
		height: 100% !important;
		width: 100% !important;
		left: 0 !important;
		top: 0 !important;
		overflow: auto;
	}

	#loginScreen .login-logo {
		display: none;
	}



	#LoginSideBar .inputLabels {
		width: 100%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 16px;
		margin-left: 22.5%;
	}


	#loginScreen .login-inputs {
		margin-top: -4vh;
	}

	#LoginSideBar .registerButtons{
		height: 30px !important;
	}

	#LoginSideBar .loginButtons {
		height: 30px !important;
	}

}

#LoginSideBar {
	height: 100%;
	background-color: rgba(255, 255, 255, 0);
}

#loginScreen .form-action {
	padding-top: .2vh;
	width: 100%;
}

#LoginSideBar .form-welcome  {
	margin-top: 8vh;
	font-weight:bold;
	color: var(--brand-color-2);
	/* letter-spacing: 5px; */
	font-size: 24px;
	font-size: 24px;
	font-family: sans-serif;
	text-align: center;
}

#LoginSideBar .form-action {
	font-weight: bold;
	color: rgb(83, 100, 110);
	font-size: 24px;
	padding-bottom: 15px;
	font-family: sans-serif;
	text-align: center;
}
#LoginSideBar .inputLabels {
	width: 100%;
	padding-top: 8px;
	padding-bottom: 5px;
	font-size: 16px;
	margin-left: 15%;
}

#LoginSideBar .inputFields {
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid grey;
	width:100%;
}

#LoginSideBar .rememberme {
	margin-top: 8px;
	/* margin-left: 34%; */
}

#LoginSideBar .loginButtons{
	height: 35px;
	background-color: var(--brand-color-1);
	border-color: var(--brand-color-1);
	width: 68%;
	color: white;
	margin-left: 16%;
	text-align: center;
	margin-bottom: 5px;
	margin-top: 5px;
}

#LoginSideBar .registerButtons {
	height: 35px;
	width: 68%;
	border-color: var(--brand-color-1);
	color: var(--brand-color-2);
	margin-bottom: 25px;
	margin-left: 16%;
}

#LoginSideBar .login-spinner{
	margin-left: 46%;
}

#LoginSideBar .login-error{
	text-align: center;
	/*margin-top: 5%;*/
	margin: 0 15%;
}
