#SettingsContainer {
  margin: 10px 25px;
}
#SettingsContainer #change-password-form {
  margin: 0 20%;
  border: solid thin var(--brand-color-1);
  border-radius: 20px;
  padding: 15px;
}

#SettingsContainer .ant-input-suffix {
  top: 50%;
}

.custom-btn {
  color: var(--brand-color-1);
  background-color: white;
	border-color: var(--brand-color-1);
	/*font-weight: 600;*/
	border-radius: 30px;
}
.custom-btn:hover {
	color: white;
  background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
}