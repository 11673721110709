/*-- User Profile --*/
#homepage {
	font-family:  "Open Sans", "Helvetica", sans-serif;
}

#homepage .sidebar {
	padding: 0 10px;
}

#homepage .group-button {
	font-size: 16px;
	/* letter-spacing: 2px; */
  padding: 15px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
	-webkit-transition: .2s;
	transition: .2s;
	cursor: pointer;
	margin: 2vh 10px 10px 10px;
	width: 95%;
}

#homepage .group-button:hover {
	background-color: var(--brand-color-2);
	color: white;
	border: none;
}

#homepage .group-button.active {
  background-color: var(--brand-color-2);
  color: white;
	border: none;
}


#homepage .card {
	width: 90%;
	margin-top: 5vh;
	color: black;
	background-color: rgba(255, 255, 255, 0.75);
	backdrop-filter: blur(6px);
	font-family:  "Open Sans", "Helvetica", sans-serif;
	font-size: 16px;
	padding: 2vh 2vw;
	padding-bottom: 2vh;
	box-shadow: rgba(0, 0, 0, 0.5);
}

#homepage .name {
	font-size: 1vw;
	font-size: 20px;
	font-weight: bolder;
	border-bottom: 2px rgb(153,154,153) solid;
	margin: 2vh 0;
}

#homepage .user-acct-btn {
	border-color: var(--brand-color-2) !important;
	color: rgba(0,0,0,1) !important;
}

#homepage .user-acct-btn:hover {
	border-color: var(--brand-color-2) !important;
	background-color: var(--brand-color-2) !important;
	color: white !important;
}

#homepage .user-form .ant-row div {
	margin-bottom: 1vh;
}

#homepage .ant-card-head div {
	color: white;
}

#homepage .ant-select-selection-selected-value  {
	color: black !important;
}

#homepage .ant-card-head div select {
	color: black;
}

/*-- Activity Dashboard --*/

#activity {
	margin-top: 2vh;
	box-shadow: 5px 5px 10px rgba(0, 0 ,0, 0.5);
	width: 90%;
}

#activity .label {
	background-color: white;
	color: black;
	width: 75vw;
}


#activity .ant-card {
	background-color: rgba(225, 225, 225, 0.75);
	backdrop-filter: blur(6px);
}

#activity .ant-card .ant-card-head {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
}

#activity .ant-select {
	width: 100%;
	max-width: 400px;
	min-width: 200px;
}


#homepage .ant-input {
	color: black;
}
