/*--Evidence Library --*/

#evidence_library {
	font-family:  "Open Sans", "Helvetica", sans-serif;
}

#evidence_library .title {
	text-align: center;
	/* font-size: 1.75vmax; */
	font-size: 12pt;
	/* font-size: 1.75vw; */
	font-size: 12pt;
	color: black;
	width: 30vw;
	margin-left: 35vw;
	margin-bottom: 3vh;
  font-weight: bolder;
}

#create-edit-evidence .title {
	color: var(--brand-color-1);
	margin-bottom: 5px;
}

#create-edit-evidence .kvmtable {
	color: var(--brand-color-1);
	transition: .25s;
}

.vertical-centered {
	/* transform: translateY(45%); */
	/* display: flex; */
  padding-top: .5%;
}

/*--Evidence Table --*/
#evidence_table {
	font-family:  "Open Sans", "Helvetica", sans-serif;
	margin-bottom: 25px;
}

#evidence_table .filter-header {
	background-color: var(--brand-color-1);
	/*background-color: rgba(102, 113, 122, 1);*/
	color: white;
	padding: 10px 0;
}

#evidence_table .filter-header .ant-checkbox-checked .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
	background-color: var(--brand-color-2) !important;
}

#evidence_table .filter-header .ant-checkbox-group-item:hover .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

#evidence_table .filter-header .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus .ant-checkbox-inner {
	border-color: var(--brand-color-2) !important;
}

#evidence_table .filter-box {
	margin-top: 5px;
	padding: 5px;
	color: rgba(0,0,0,1);
	width: 80%;
	height: 100%;
	min-height: 30px;
}

#evidence_table .filter-box::placeholder {
	color: rgba(0,0,0,1);
}

#evidence_table .evidence_filter {
	color: black;
}

#evidence_table .big-label {
  /* font-size: 2vw; */
  /* font-size: 2vmax; */
  height: 2vh;
  margin-top: 2vh;
  margin-left: 1vw;
}

#evidence_table .check-section {
	border-left: solid 2px white;
	border-right: solid 2px white;
	text-align: center;
	height: 100%;
	padding: 0 5px;
	margin: 0;
	width: 49vw;
}

@media (max-width: 991px) {
	#evidence_table .check-section {
		border: none;
		margin: 10px 0 15px;
	}
}

#evidence_table .check-section.noborder {
	border: none;
}

#evidence_table .checks {
  padding: 1vh 1vw;
  border: white solid thin;
  border-radius: 6px;
  width: 20vw;
}

#evidence_table .ant-checkbox-wrapper {
  font-size: 1vw;
  font-size: 1vmax;
	color: white;
}

#evidence_table .evidence_fixed {
	/* position: absolute; */
	/* top: 0; */
	/* right: 5%; */
	margin-top: 1vh;
	/* margin-right: 1.7vh; */
	text-align: center;
}

#evidence_table .selected_evidence {
	background-color: rgba(255, 255, 255, 0.85);
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
	border: 1px solid rgba(102, 113, 122, 1);
	padding: 1vh 1vw 0 1vw;
	margin: 20px;
	text-align: center;
}

#evidence_table .selected_evidence .selected-evidence-header {
	color: white;
	background-color: var(--brand-color-2);
	height: 8vh;
	line-height: 2vh;
	padding-top: 3vh;
}

#evidence_table .selected_evidence .reference {
	font-size: 1vmax;
	padding: 15px 0;
	overflow: auto;
	font-weight: bolder;
	border-top: 2px solid rgba(102, 113, 122, 1);
	border-bottom: 2px solid rgba(102, 113, 122, 1);
	overflow-x: hidden;
}

#evidence_table .selected_evidence .abstract {
	font-size: 10.5pt;
	height: 30vh;
	overflow: auto;
	text-align: justify;
	overflow-x: hidden;
	margin-bottom: 2vh;
	padding-right: 1vh;
}

#evidence_table .evidence_table {
	margin-top: 10px;
	max-height: 100vh;
	min-height: 68vh;
	overflow-y: auto;
	/* border-radius: 5px; */
	padding: 1vh 1vw 1vh 3vw;
}


#evidence_table .evidence_row {
  text-align: left;
	font-family:  "Open Sans", "Helvetica", sans-serif;
  color: white;
  font-size: 0.9em;
  font-size: 1vmax;
	/* padding: 3vh 1vw 2vh 1vw; */
	padding: 1vh 1vw 1vh 1vw;
	margin-bottom: .5vh;
	border-radius: 5px;
	color: var(--brand-color-1);
	margin-right: 2vw;
	background-color: white;
	transition: background-color .75s, box-shadow .75s;
	box-shadow: inset 0px -1px 3px var(--brand-color-1), inset 0px 0px 3px var(--brand-color-1), inset 0px 1px 3px var(--brand-color-1);
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	list-style: none;
	width: 95%;
}

#evidence_table .evidence_row p {
	margin-bottom: 0;
}

#evidence_table .evidence_row:hover {
	box-shadow: inset 0px -2px 3px var(--brand-color-2), inset 0px 0px 3px var(--brand-color-2), inset 0px 2px 3px var(--brand-color-2);
}

#evidence_table .evidence_row.selected {
	margin-bottom: 0px;
	border-radius: 5px 5px 0px 0px;
	color: white;
	background-color: var(--brand-color-1);
	box-shadow: inset 0px -1px 3px var(--brand-color-1), inset 0px 0px 3px var(--brand-color-1), inset 0px 1px 3px var(--brand-color-1);
}

#evidence_table .evidence_row_abstract {
	text-align: left;
	padding: 1vh 1vw 1vh 1vw;
	background-color: #F5F6FF;
	border: solid thin var(--brand-color-1);
	margin-bottom: 1.5vh;
	margin-right: 2vw;
	border-radius: 0px 0px 5px 5px;
	position: relative;
	color: black;
	font-size: 9.5pt;
	width: 95%;
}


#evidence_table .cat-images {
	background-color: var(--brand-color-1);
	width: 3vw;
	padding: .5vw;
	min-height: 100px;
	margin-bottom: 1vh;
}

#evidence_table .background {
	background-color: #ebf8fd;
	margin-right: 25px;
	border: solid thin var(--brand-color-1);
	border-radius: 10px;
  /* background-size: cover; */
	/* background-image: url('../../static/images/1382_1920.jpg'); */
  /* height: 100vh; */
	/* width: 100%; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
}

.ant-input-group-addon > button {
	background-color: var(--brand-color-2);
	border-color: var(--brand-color-2);
}

@media (max-width: 767px) {
	#evidence_table .cat-images {
		background-color: var(--brand-color-1);
		width: 100%;
		min-height: 0;
		margin-bottom: 1vh;
		display: flex;
		justify-content: center;
	}

	#evidence_table .evidence-cat {
		margin: auto 5px;
		width: 25px !important;
	}

	#evidence_table .type {
		margin-bottom: 5px !important;
		font-size: 12px !important;
	}

	#evidence_table .reference p, #evidence_table .reference, #evidence_table .selected_evidence .abstract {
		font-size: 12px !important;
	}

	#evidence_table .evidence-btn {
		font-size: 12px !important;
		width: 98% !important;
		height: 30px;
	}
}

#evidence_table .cat-images img {
	margin-bottom: 5px
}

#evidence_table .evidence-cat {
	width: 2vw;
	display: inline-block;
}

#evidence_table .action-icon {
  width: 1.75vw;
  margin-left: .5vw;
  margin-right: .5vw;
	cursor: pointer;
}

#evidence_table .action-icon.smaller {
	width: 1.25vw;
}

#evidence_table .evidence_row .reference {
	font-size: 10pt;
	/* font-size: 1vmax; */
}

#evidence_table .evidence_row .type {
	font-size: 1vw;
	font-size: 1vmax;
	text-align: center;
}

#evidence_table .evidence-summary-icons {
	background-color: var(--brand-color-2);
	align-self: flex-end;
	margin-bottom: 5px;
}

/* #evidence_table .ant-modal-wrap {
	overflow: hidden;
} */

/*-- New Evidence Form --*/
.selected-evidence-btn {
	width: 90%;
	display: inline-block;
}


#evidence {
	/* width: 80%; */
	/* margin-left: 10%; */
	cursor: pointer;
}

#newevidence .title {
	font-size: 2vw;
	font-size: 2vmax;
}



#newevidence .field {
	color: var(--brand-color-1);
	margin-bottom: 1vh;
	font-weight: bolder;
	/*padding: 2vh, 1vw 1vh 1vw;*/
}

#newevidence .check {
	font-weight: normal;
}


#newevidence .shortInput {
	padding: .7vh .7vw;
	/* width: 95%; */
}

#newevidence .longInput {
	padding: .7vh .7vw;
	/* width: 90%; */
}

#newevidence .table {
	overflow: auto;
	max-height: 70vh;
	display: inline-block;
}

#newevidence .evidencetable {
	overflow: auto;
	max-height: 50vh;
	display: inline-block;
}

#newevidence .kvm-message {
	padding: 0vh 2vw;
}

#newevidence .kvm-label:nth-child(even) {
	background-color: #CCCBE1;
	height: 7vh;
	margin: 0;
	border: thin white solid;
}

#newevidence .kvm-label:nth-child(odd) {
	background-color: #E7E7F0;
	height: 7vh;
	margin: 0;
	border: thin white solid;
}

#newevidence #file-upload {
	font-family: sans-serif;
}

#newevidence .ant-select-selection__placeholder {
	font-weight: normal;
}

.new_evidence_btn {
	background-color: white !important;
	border-color: #718692;
	color: #718692 !important;
	border-color: #718692 !important;
	border-radius: 20px;
	padding: 1vh 1vw 0.75vh 1vw;
	width: 80%;
	margin: 0 10%;
}

.new_evidence_btn:hover {
	background-color: #718692 !important;
	color: white !important
}


.add_new_evidence_btn {
	/* background-color: white;
	border-color: var(--brand-color-2);
	color: rgba(0,0,0,1);
	border-radius: 20px;
	margin: 0 10%; */
	background-color: white !important;
	border-color: var(--brand-color-1) !important;
	color: var(--brand-color-1) !important;
	text-align: center;
	border-radius: 20px;
	/*width: 10vw;*/
	width: 100%;
	font-size: 14px;
}

.add_new_evidence_btn:hover, .add_new_evidence_btn:active, .add_new_evidence_btn:focus {
	color: white !important;
	background-color: var(--brand-color-2) !important;
	border-color: var(--brand-color-2) !important;
}

/* KVM STEP */

#create-edit-evidence .kvmtable {
	position: relative;
	overflow: auto;
	height: auto;
	max-height: 53vh;
	display: inline-block;
}

#create-edit-evidence .kvm-column {
	background-color: #E2E7E9;
	color: #53646E;
}

#create-edit-evidence .kvm-id {
	transition: background-color .25s, color .10s;
	width: 2%;
}

#create-edit-evidence .kvm-message {
	text-align: left;
	/* padding: 0vh 2vw; */
}

#create-edit-evidence .kvm-label {
	height: 7vh;
	margin: 0;
	border-bottom: solid medium #EEEDED;
	transition: .25s;
	font-size: 9pt;
}

#create-edit-evidence .kvm-label:hover {
	background-color: #b4bfc5;
	color: white;
}

#create-edit-evidence .kvm-checkbox {
	transition: .25s;
	padding: 0;
	margin: 0;
	width: 10px;
}

#create-edit-evidence .kvm-checkbox-header {
	width: 5% !important;
}

#create-edit-evidence .kvm-label td {
	padding: 1vh .5vw .5vh .5vw;
}

#create-edit-evidence table td {
	text-align: left;
}

#create-edit-evidence table th {
	padding-left: 0;
	padding-right: 0;
}

#evidence_library th, td {
	min-width: 100px !important;
	font-size: 9pt;
}

.evidence-summary-btn {
	color: var(--brand-color-2);
	border-color: var(--brand-color-2);
	width: 97%;
	margin-top: 0.25vh;
	margin-bottom: 0px;
	border-radius: 30px;
	font-family:  "Open Sans", "Helvetica", sans-serif;
}

.evidence-summary-btn:hover, .evidence-summary-btn:active, .evidence-summary-btn:focus {
	color: white;
	border-color: var(--brand-color-1);
	background-color: var(--brand-color-1);
}